import { createOrderModalVisibleAtom } from "@/atoms/createOrderModalAtom"
import { isPayingUserSelector, userAtom } from "@/atoms/userAtom"
import { NEW_ORDERS_PAGE_PATH, PROFILE_PAGE_PATH } from "@/constants/routes"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import PreventWithModal from "@/UI/PreventWithModal"
import {
  faFileImport,
  faPlus,
  faUserGroup,
  faUserPlus,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dropdown, message } from "antd"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import UploadTemplateModal from "../UploadTemplate/components/UploadTemplateModal"
import { useUser } from "@/hooks/useUser"
import { draftOrdersAtom } from "@/atoms/draftOrdersAtom"
import OptimizationModalButton from "../RouteOptimizationModal/components/OptimizationModalButton"
import { createRoutesAtom } from "@/atoms/createRoutesAtom"
import { getDraftOrders } from "@/api/draftorder"
import DriverModal from "@/modules/profile/DriversSettings/components/DriverModal"
import CreateTeamModal from "@/modules/profile/TeamSettings/components/CreateTeamModal"

export enum CreateOrderActions {
  CREATE_SINGLE = "single",
  CREATE_ROUTES = "create_routes",
  IMPORT = "import",
}

interface GlobalCreateOrderButtonProps {
  actions?: CreateOrderActions[] | CreateOrderActions
}

const GlobalCreateOrderButton: React.FC<GlobalCreateOrderButtonProps> = ({
  actions = [
    CreateOrderActions.CREATE_ROUTES,
    CreateOrderActions.CREATE_SINGLE,
    CreateOrderActions.IMPORT,
  ],
}) => {
  const { t } = useTranslation()
  const { sandboxRouter } = useSandboxRouter()
  const { fetchStores } = useUser()
  const { preference } = useRecoilValue(userAtom)

  const [draftOrders, setDraftOrders] = useRecoilState(draftOrdersAtom)
  const [createRoutes, _] = useRecoilState(createRoutesAtom)
  const setCreateOrderModalVisible = useSetRecoilState(createOrderModalVisibleAtom)
  const isPayingUser = useRecoilValue(isPayingUserSelector)
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false)

  const items = [
    {
      key: "0",
      type: "group",
      label: t("common.routes"),
      children: [
        ...(actions.includes(CreateOrderActions.CREATE_ROUTES)
          ? [
              {
                key: CreateOrderActions.CREATE_ROUTES,
                label: (
                  <OptimizationModalButton
                    isDisabled={draftOrders?.metadata?.total === 0}
                    type="default"
                    overrideButton={<p>{t("new-orders.RouteOptimizationModal.createRoutes")}</p>}
                    optimizationCallback={async () => {
                      await getDraftOrders(createRoutes?.filters)
                    }}
                  />
                ),
                icon: (
                  <FontAwesomeIcon className="flex flex-shrink-0 h-3" icon={faWandMagicSparkles} />
                ),
              },
            ]
          : []),
        ...(actions.includes(CreateOrderActions.CREATE_SINGLE)
          ? [
              {
                key: CreateOrderActions.CREATE_SINGLE,
                label: (
                  <PreventWithModal
                    enable={!isPayingUser}
                    label={t("onboarding.freePlanBannerDescription")}
                    cta={{
                      label: t("profile.planCard.startNow"),
                      onClick: () => sandboxRouter.push(`${PROFILE_PAGE_PATH}/?p=plans`),
                    }}
                  >
                    <div className="flex gap-x-2 items-center">
                      <FontAwesomeIcon icon={faPlus} />
                      <p>{t("create-order.createSingleOrder")}</p>
                    </div>
                  </PreventWithModal>
                ),
                onClick: () => setCreateOrderModalVisible({ visible: true }),
              },
            ]
          : []),
        ...(actions.includes(CreateOrderActions.IMPORT)
          ? [
              {
                key: CreateOrderActions.IMPORT,
                label: (
                  <PreventWithModal
                    enable={!isPayingUser}
                    label={t("onboarding.freePlanBannerDescription")}
                    cta={{
                      label: t("profile.planCard.startNow"),
                      onClick: () => sandboxRouter.push(`${PROFILE_PAGE_PATH}/?p=plans`),
                    }}
                  >
                    <p>{t("common.importFile")}</p>
                  </PreventWithModal>
                ),
                icon: <FontAwesomeIcon className="flex flex-shrink-0 h-3" icon={faFileImport} />,
                onClick: () => {
                  setImportModalVisible(true)
                },
              },
            ]
          : []),
      ],
    },
    {
      key: "1",
      type: "group",
      label: t("common.drivers"),
      children: [
        {
          key: "create-driver",
          label: <DriverModal button={<p>{t("common.createDriver")}</p>} />,
          icon: <FontAwesomeIcon icon={faUserPlus} />,
        },
        {
          key: "create-team",
          label: <CreateTeamModal button={<p>{t("profile.TeamsSettings.createTeam")}</p>} />,
          icon: <FontAwesomeIcon icon={faUserGroup} />,
        },
      ],
    },
  ]

  const uploadCallback = async () => {
    try {
      if (sandboxRouter?.pathname?.includes(NEW_ORDERS_PAGE_PATH)) {
        setDraftOrders((draftOrdersState) => ({ ...draftOrdersState, triggerRefresh: true }))
      }
      fetchStores()
    } catch (err: any) {
      message.error(t("errors.draftUpload.processTemplate"), err)
      throw err
    }
  }
  const boxShadowClass = !preference?.branding?.platformEnabled
    ? "dropdown-button-shadow"
    : "[&_button]:border-none [&_button]:transition-shadow [&_button]:shadow-[0_4px_6px_rgba(0,0,0,0.5)] [&_button]:hover:shadow-[0_6px_8px_rgba(0,0,0,0.5)]"

  return (
    <>
      <div className="flex w-fit">
        <Dropdown.Button
          type="primary"
          className={boxShadowClass}
          menu={{ items }}
          buttonsRender={([leftButton, _]) => [null, leftButton]}
          trigger={["click"]}
        >
          <div className="flex gap-x-2 items-center">
            <FontAwesomeIcon icon={faPlus} />
            <p>{t("common.create")}</p>
          </div>
        </Dropdown.Button>
      </div>
      <UploadTemplateModal
        open={importModalVisible}
        onClose={() => setImportModalVisible(false)}
        handleUploadCallback={uploadCallback}
        withPickup={true}
      />
    </>
  )
}

export default GlobalCreateOrderButton
