import { Tooltip } from "antd"
import React from "react"
import BrandedQuestionMark from "../Icons/BrandedQuestionMark"
import { SemiBoldLabelText } from "../Texts"

export const LabelWithTooltip: React.FC<{
  tooltipContent: string
  label: string
  isColored?: boolean
}> = ({ label, tooltipContent, isColored = true }) => {
  return (
    <div className="flex gap-x-1 items-center">
      <SemiBoldLabelText>{label}</SemiBoldLabelText>
      <Tooltip title={tooltipContent}>
        <div className="mb-1">
          <BrandedQuestionMark isColored={isColored} />
        </div>
      </Tooltip>
    </div>
  )
}
